var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navigation",class:[
    'navigation-' + _vm.layout,
    'navigation-responsive-' + _vm.responsive.layout,
    {
        'navigation--mobile': _vm.isMobile,
        'navigation--opened': _vm.isOpen,
        'pos-sticky': _vm.sticky,
    }
],attrs:{"role":"navigation","aria-label":"main navigation"}},[_c('div',{class:[{ 'c pv-r': _vm.width }, _vm.width ? 'w-' + _vm.width : '']},[_c('div',{staticClass:"nav__container flex -align-center pv-s"},[(_vm.logo)?_c('router-link',{staticClass:"navigation__logo",attrs:{"to":"/"}},[_c('InlineSvg',{attrs:{"src":_vm.logo}})],1):_vm._e(),(_vm.isMobile)?_c('icon',{staticClass:"mdi mdi-menu size-3 navigation__hamburger transition cursor-pointer",attrs:{"aria-label":"menu"},on:{"click":function($event){return _vm.$store.commit('toggleValue', { action: 'toggle', key: 'nav' })}}}):_vm._e(),_c('transition',{attrs:{"name":_vm.isMobile ? 'fade-up' : ''}},[_c('NavigationPages',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"navigation__pages",attrs:{"id":"nav"}},'NavigationPages',{
                    pages: _vm.pages,
                    iconProvider: _vm.iconProvider,
                    isMobile: _vm.isMobile,
                    level: 0,
                },false))],1),(_vm.$scopedSlots.content)?_c('div',{staticClass:"navigation__slot"},[_vm._t("content")],2):_vm._e(),(_vm.languages.list && _vm.languages.list.length > 1)?_c('ul',{class:['navigation__lang', 'navigation__lang-' + _vm.languages.layout]},_vm._l((_vm.languages.list),function(lang){return _c('li',{key:'lang_' + lang.id,staticClass:"cursor-pointer transition",class:{ '-active': _vm.languages.current === lang.id },attrs:{"title":lang.title || ''},on:{"click":function($event){return _vm.$store.dispatch('Language/set', lang.id)}}},[_vm._v(" "+_vm._s(lang.name)+" ")])}),0):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }