<!-- navigation 1.1.0 -->
<template>
    <nav class="navigation" :class="[
        'navigation-' + layout,
        'navigation-responsive-' + responsive.layout,
        {
            'navigation--mobile': isMobile,
            'navigation--opened': isOpen,
            'pos-sticky': sticky,
        }
    ]" role="navigation" aria-label="main navigation">
        <div :class="[{ 'c pv-r': width }, width ? 'w-' + width : '']">
            <div class="nav__container flex -align-center pv-s">
                <router-link v-if="logo" to="/" class="navigation__logo">
                    <InlineSvg :src="logo" />
                </router-link>

                <icon v-if="isMobile" class="mdi mdi-menu size-3 navigation__hamburger transition cursor-pointer"
                    @click="$store.commit('toggleValue', { action: 'toggle', key: 'nav' })" aria-label="menu" />

                <transition :name="isMobile ? 'fade-up' : ''">
                    <NavigationPages v-show="isOpen" class="navigation__pages" id="nav" v-bind="{
                        pages,
                        iconProvider,
                        isMobile,
                        level: 0,
                    }" />
                </transition>

                <div v-if="$scopedSlots.content" class="navigation__slot">
                    <slot name="content" />
                </div>

                <ul v-if="languages.list && languages.list.length > 1"
                    :class="['navigation__lang', 'navigation__lang-' + languages.layout]">
                    <li v-for="lang in languages.list" :key="'lang_' + lang.id" :title="lang.title || ''"
                        class="cursor-pointer transition" :class="{ '-active': languages.current === lang.id }"
                        @click="$store.dispatch('Language/set', lang.id)">
                        {{ lang.name }}
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'Navigation',
    components: {
        InlineSvg: () => import('vue-inline-svg'),
        NavigationPages: () => import('./NavigationPages.vue'),
    },
    props: {
        pages: {
            type: Array,
            default: () => [],
        },
        iconProvider: {
            type: String,
            default: 'mdi',
        },
        logo: String,
        layout: {
            type: String,
            default: 'traditional',
        },
        languages: {
            type: Object,
            default: () => ({ list: [], current: '', layout: 'show' }), // { list: [], current: '', layout: 'show' }
        },
        responsive: {
            type: Object,
            default: () => ({}),
        },
        sticky: Boolean,
        width: String,
    },
    computed: {
        isMobile() {
            return this.responsive.breakpoint
                && this.$store.getters.size('lte', this.responsive.breakpoint);
        },

        isOpen() {
            return !this.isMobile || this.$store.state.app.nav_opened;
        },
    },
};
</script>

<style>
.navigation {
    --navigation-link-color: var(--color-contrast-secondary);
    --navigation-link-activecolor: var(--color-accent);
    --navigation-link-hovercolor: var(--color-accent);
    --navigation-logo-color: var(--color-contrast-primary);
    --navigation-logo-hovercolor: var(--color-contrast-secondary);
    z-index: 15;
}

.navigation:not(.pos-sticky) {
    position: relative;
    z-index: 20;
}

.navigation-traditional .nav__container {
    justify-content: space-between;
    position: relative;
}

.navigation-traditional .navigation__pages {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
}

.navigation-traditional .navigation__link:not(.dropdown-item) {
    padding-left: var(--size);
    padding-right: var(--size);
    position: relative;
}

.navigation-traditional .navigation__link icon {
    margin-right: var(--size-s);
}

.navigation-traditional .navigation__link.navigation__icon-right icon {
    margin-left: var(--size-s);
    margin-right: 0;
    order: 99;
}

.navigation-traditional .navigation__slot {
    flex: 0 0 auto;
}

/* Lang */
.navigation__lang {
    margin: 0;
    padding: 0;
}

.navigation__lang-show {
    display: flex;
}

.navigation__lang-show li {
    padding-left: var(--size);
    padding-right: var(--size);
}

.navigation--mobile:not(.navigation--opened) .navigation__lang-show {
    display: none;
}

/* Mobile */
.navigation-traditional.navigation--mobile .nav__container {
    flex-wrap: wrap;
}

.navigation-traditional.navigation--mobile .navigation__pages {
    flex-direction: column;
    flex: 0 0 100%;
    padding: var(--size) 0;
    align-items: stretch;
}

.navigation-traditional.navigation--mobile .navigation__link {
    padding-top: var(--size);
    padding-bottom: var(--size);
}

.navigation-traditional.navigation--mobile .navigation__pages ul {
    margin-left: var(--size);
    border-left: 0.1rem solid var(--color-secondary);
    padding-left: var(--size);
    font-size: 87%;
}

.navigation-responsive-scroll {
    overflow-x: auto;
}

/* Colors */
.navigation__link:not(.dropdown-item),
.navigation__hamburger,
.navigation__lang li:not(.dropdown-item) {
    color: var(--navigation-link-color);
}

a.navigation__link:not(.dropdown-item):hover,
.navigation__hamburger:hover,
.navigation__lang li:not(.dropdown-item):hover {
    color: var(--navigation-link-hovercolor);
}

a.navigation__link:not(.dropdown-item).router-link-active,
a.navigation__link:not(.dropdown-item).-active,
.navigation__lang li:not(.dropdown-item).-active {
    color: var(--navigation-link-activecolor);
}

.navigation__logo svg {
    fill: var(--navigation-logo-color);
    transition: var(--transition);
}

.navigation__logo:hover svg {
    fill: var(--navigation-logo-hovercolor);
}
</style>
